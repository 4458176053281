<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>General Settings</h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link>\ General Settings
                </div>
              </div>
            </div>
          </div>

          <div class="card-body">
            <form @submit.prevent="saveGeneralSetting">
              <v-row>
                <v-col cols="12" md="6">
                  <div class="form-group p-4 text-center">
                    <img id="logo_preview" :src="imgurl" class="img-thumbnail" alt="">
                  </div>
                  <div class="form-group my-4">
                    <span class="is-invalid text-danger" v-if="errors.logo">* {{errors.logo[0]}}</span>
                    <span
                      class="text-danger"
                      v-if="$v.option.logo.$error"
                      >
                      This information is required
                    </span>
                    <v-file-input v-model="option.logo"
                                  outlined
                                  dense
                                  ref="logoInput"
                                  @change="previewImage"
                                  placeholder="Logo"
                                  label="Browse Logo" prepend-icon="mdi-paperclip">
                      <template v-slot:selection="{ text }">
                        <v-chip small label color="primary">
                          {{ text }}
                        </v-chip>
                      </template>
                    </v-file-input>
                  </div>
                  
                  <div class="form-group my-4">
                    <v-text-field
                        outlined
                        dense
                        label="Company Name"
                        v-model="option.company_name">
                    </v-text-field>
                    <span class="is-invalid text-danger" v-if="errors.company_name">* {{errors.company_name[0]}}</span>
                    <span
                      class="text-danger"
                      v-if="$v.option.company_name.$error"
                      >
                      This information is required
                    </span>
                  </div>

                  <div class="form-group my-4">
                    <v-text-field
                        outlined
                        dense
                        label="Email Address"
                        v-model="option.email">
                    </v-text-field>
                    <label class="text text-gray">This address is used for admin purposes, like new user notification.</label>
                    <span class="is-invalid text-danger" v-if="errors.email">* {{errors.email[0]}}</span>
                    <div
                      class="text-danger"
                      v-if="$v.option.email.$error"
                      >
                      This information is required
                    </div>
                  </div>
                  <div class="form-group my-4">
                    <v-text-field
                        outlined
                        dense
                        label="Contact Number"
                        v-model="option.contact_number">
                    </v-text-field>
                    <span class="is-invalid text-danger" v-if="errors.contact_number">* {{errors.contact_number[0]}}</span>
                    <span
                      class="text-danger"
                      v-if="$v.option.contact_number.$error"
                      >
                      This information is required
                    </span>
                  </div>
                  
                  <div class="form-group my-4">
                      <v-select
                        outlined
                        dense
                        v-model="option.date_format"
                        :items="dateFormats"
                        item-text="text"
                        item-value="value"
                        label="Date Format*"
                        required
                    ></v-select>
                    <span
                      class="text-danger"
                      v-if="$v.option.date_format.$error"
                      >
                      This information is required
                    </span>
                    <span class="is-invalid text-danger" v-if="errors.date_format">* {{errors.date_format[0]}}</span>
                  </div>

                  <div class="form-group my-4">
                    <v-text-field
                        outlined
                        dense
                        label="No. of Practice Exams"
                        v-model="option.no_of_practice_exams">
                    </v-text-field>
                    <span class="is-invalid text-danger" v-if="errors.no_of_practice_exams">* {{errors.no_of_practice_exams[0]}}</span>
                    <span
                      class="text-danger"
                      v-if="$v.option.no_of_practice_exams.$error"
                      >
                      This information is required
                    </span>
                  </div>

                  <div class="form-group my-4">
                    <v-text-field
                        outlined
                        dense
                        label="Renewable Fee"
                        v-model="option.renewable_fee">
                    </v-text-field>
                    <span
                      class="text-danger"
                      v-if="$v.option.renewable_fee.$error"
                      >
                      This information is required
                    </span>
                    <span class="is-invalid text-danger" v-if="errors.renewable_fee">* {{errors.renewable_fee[0]}}</span>
                  </div>
                </v-col>

                <v-col cols="12" md="6">
                  <div class="form-group my-4">
                    <v-text-field
                        outlined
                        dense
                        label="Appeal Fee for 2 Questions"
                        v-model="option.appeal_two_questions">
                    </v-text-field>
                    <span
                      class="text-danger"
                      v-if="$v.option.appeal_two_questions.$error"
                      >
                      This information is required
                    </span>
                    <span class="is-invalid text-danger" v-if="errors.appeal_two_questions">* {{errors.appeal_two_questions[0]}}</span>
                  </div>
                  
                  <div class="form-group my-4">
                    <v-text-field
                        outlined
                        dense
                        label="Appeal Fee for Whole Exam"
                        v-model="option.appeal_whole_exams">
                    </v-text-field>
                    <span
                      class="text-danger"
                      v-if="$v.option.appeal_whole_exams.$error"
                      >
                      This information is required
                    </span>
                    <span class="is-invalid text-danger" v-if="errors.appeal_whole_exams">* {{errors.appeal_whole_exams[0]}}</span>
                  </div>
                  
                  <div class="form-group my-4">
                    <v-text-field
                        outlined
                        dense
                        label="Result Shipping Cost"
                        v-model="option.result_shipping_cost">
                    </v-text-field>
                    <span
                      class="text-danger"
                      v-if="$v.option.result_shipping_cost.$error"
                      >
                      This information is required
                    </span>
                    <span class="is-invalid text-danger" v-if="errors.result_shipping_cost">* {{errors.result_shipping_cost[0]}}</span>
                  </div>

                  <div class="form-group my-4">
                    <v-text-field
                        outlined
                        dense
                        label="Result Shipping and Tracking Cost"
                        v-model="option.result_shipping_tracking_cost">
                    </v-text-field>
                    <span
                      class="text-danger"
                      v-if="$v.option.result_shipping_tracking_cost.$error"
                      >
                      This information is required
                    </span>
                    <span class="is-invalid text-danger" v-if="errors.result_shipping_tracking_cost">* {{errors.result_shipping_tracking_cost[0]}}</span>
                  </div>
                  
                  <div class="form-group my-4">
                    <v-text-field
                        outlined
                        dense
                        label="Government Service Tax (GST) in %"
                        v-model="option.gst">
                    </v-text-field>
                    <span
                      class="text-danger"
                      v-if="$v.option.gst.$error"
                      >
                      This information is required
                    </span>
                    <span class="is-invalid text-danger" v-if="errors.gst">* {{errors.gst[0]}}</span>
                  </div>
                  
                  <div class="form-group my-4">
                    <v-text-field
                        outlined
                        dense
                        label="Government Service Tax (GST)-NZL %"
                        v-model="option.gst_nzl">
                    </v-text-field>
                    <span
                      class="text-danger"
                      v-if="$v.option.gst_nzl.$error"
                      >
                      This information is required
                    </span>
                    <span class="is-invalid text-danger" v-if="errors.gst_nzl">* {{errors.gst_nzl[0]}}</span>
                  </div>
                  
                  <div class="form-group my-4">
                    <v-text-field
                        outlined
                        dense
                        label="Australian Business Number(ABN)"
                        v-model="option.abn">
                    </v-text-field>
                    <span
                      class="text-danger"
                      v-if="$v.option.abn.$error"
                      >
                      This information is required
                    </span>
                    <span class="is-invalid text-danger" v-if="errors.abn">* {{errors.abn[0]}}</span>
                  </div>

                  <div class="form-group my-4">
                    <v-text-field
                        outlined
                        dense
                        label="ACN"
                        v-model="option.acn">
                    </v-text-field>
                    <span
                      class="text-danger"
                      v-if="$v.option.acn.$error"
                      >
                      This information is required
                    </span>
                    <span class="is-invalid text-danger" v-if="errors.acn">* {{errors.acn[0]}}</span>
                  </div>
                  
                  <div class="form-group my-4">
                    <v-text-field
                        outlined
                        dense
                        label="Extension Period"
                        v-model="option.extension_period">
                    </v-text-field>
                    <span
                      class="text-danger"
                      v-if="$v.option.extension_period.$error"
                      >
                      This information is required
                    </span>
                    <span class="is-invalid text-danger" v-if="errors.extension_period">* {{errors.extension_period[0]}}</span>
                  </div>
                  
                  <div class="form-group my-4">
                    <v-text-field
                        outlined
                        dense
                        type="number"
                        label="Lock Time (Marking)"
                        v-model="option.lock_time">
                    </v-text-field>
                    <span
                      class="text-danger"
                      v-if="$v.option.lock_time.$error"
                      >
                      This information is required
                    </span>
                    <span class="is-invalid text-danger" v-if="errors.lock_time">* {{errors.lock_time[0]}}</span>
                  </div>
                  
                  <div class="form-group text-right">
                    <v-btn
                      class="mt-1 btn btn-primary"
                      style="color:#fff;"
                      type="submit"
                    >
                        <v-icon small  elevation="2" outlined>fas fa-save</v-icon>&nbsp;
                        Save setting
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </form>
          </div>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>

import OptionService from "@/services/cms/setting/option/OptionService";
const optionService = new OptionService();
import {required} from "vuelidate/lib/validators";

export default {
  validations:{
    option:{
      abn: {},
      acn: {},
      company_name: {required},
      contact_number: {},
      date_format: {required},
      email: {required},
      extension_period: {},
      gst: {},
      gst_nzl: {},
      no_of_practice_exams: {},
      renewable_course_fee: {},
      renewable_fee: {},
      rising_software_maintenance: {},
      appeal_two_questions: {},
      appeal_whole_exams: {},
      result_shipping_cost: {},
      result_shipping_tracking_cost: {},
      logo: {},
      lock_time: {},
    }
  },
  data() {
    return {
      imgurl: null,
      dialog: false,
      option: {
        abn: null,
        acn: null,
        company_name: '',
        contact_number: '',
        date_format: '',
        email: '',
        extension_period: null,
        gst: null,
        gst_nzl: null,
        no_of_practice_exams: null,
        renewable_course_fee: null,
        renewable_fee: null,
        rising_software_maintenance: null,
        appeal_two_questions: null,
        appeal_whole_exams: null,
        result_shipping_cost: null,
        result_shipping_tracking_cost: null,
      },
      dateFormats : [],
      weeks : [],
      errors: [],
    }
  },
  methods: {
    convertToFormData() {
      let formData = new FormData();
      for (let key in this.option) {
          if (key === "logo" && this.option[key] != null && this.option[key] != undefined) {
              formData.append('logo', this.option[key]);
          }
          else {
              if(this.option[key]){
                  formData.append(key,this.option[key]);
              }
          }
      }
      return formData;
    },
    saveGeneralSetting() {
      this.$v.$touch();
      if(this.$v.$error){
        setTimeout(() => {
          this.$v.$reset()
        }, 3000)
      }
      else{
        let formData = this.convertToFormData();
        optionService
        .create(formData)
        .then((response) => {
          this.$snotify.success('Saved Sucessfully');
          this.getOption();
          this.getDateformatsAndWeek();
        })
        .catch((error) => {
          this.errors = error.response.data.errors; 
          this.$snotify.error("Oops something went wrong");
        });
      }
    },
    previewImage(){
      if(this.option.logo != null){
        this.imgurl= URL.createObjectURL(this.option.logo)
      }else{
        this.imgurl = null;
      }
    },
    getDateformatsAndWeek(){
      optionService
      .getDateformatsAndWeek()
      .then(response => {
        for(let key in response.data.dateFormats){
          this.dateFormats.push({
            'value' : key,
            'text' : response.data.dateFormats[key]
          })
        }
        for(let key in response.data.weeks){
          this.weeks.push({
            'value' : key,
            'text' : response.data.weeks[key]
          })
        }
      })
      .catch((error) => {

      }); 
    },
    getOption(){
      optionService
      .index()
      .then(response => {
        this.option = response.data;
        this.imgurl = this.option.logo_path;
      })
      .catch((error) => {

      });
    }
  },
  mounted(){
    this.getOption();
    this.getDateformatsAndWeek();
  }
}
</script>

<style scoped>
#logo_preview{
  height: 100px;
  width: 100px;
  object-fit: contain;
}
</style>
