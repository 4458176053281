import Apiservice from '@/common/api.service';
import {API_URL} from '@/common/config.js'

const apiService = Apiservice;

export default class OptionService  {
    #api = null;

    constructor() {
        this.#api = API_URL + 'admin/option';
    }

    index() {
        let url = this.#api;
        return apiService.get(url);
    }


    create(data) {
        let url = `${this.#api}`
        return apiService.post(url, data);
    }

    getDateformatsAndWeek(){
        let url = `${this.#api}/create`;
        return apiService.get(url);
    }
}
